// loader-interceptor.service.ts
import { Injectable } from "@angular/core";
import {
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { LoaderService } from "../loader.service";
import { catchError, finalize, tap } from "rxjs/operators";

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private requests = [];

  constructor(private loaderService: LoaderService) {}

  removeRequest(req) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    this.loaderService.isLoading.next(this.requests.length > 0);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.requests.push(req);
    this.loaderService.isLoading.next(true);
  
    return next.handle(req).pipe(
      tap((event) => {
        if (event instanceof HttpResponse) {
          this.removeRequest(req);
        }
      }),
      catchError((err) => {
        console.log("Error:", err);
        this.removeRequest(req);
        return throwError(() => err);
      }),
      finalize(() => {
        this.removeRequest(req);
      })
    );
  }
  
}
