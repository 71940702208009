<!-- Current customer type = {{ userService.customerTypeID }} -->

<div class="home-header" [ngClass]="{'midHeader': (isTop && (currentRouterPath == '/home')) , 'midHeader1' : !isTop }">

  <!-- *
  
  Not using below ng-template code!
  
  -->

  <ng-template>
    <ng-container *ngIf="currentRouterPath == '/home'">
      <mat-toolbar fxShow fxHide.lt-md color="primary"
        [ngClass]="{'noColor header p-0-10': isTop , 'kuro header' : !isTop }" fxFlex="100">
        <mat-toolbar-row class="container p-0 h-90">
          <div class="mt p-l-20 p-r-20" fxLayout="row">
            <div>
              <p><a class="c-white logo" [ngClass]="{'c-white': isTop , 'c-black' : !isTop }"
                  routerLink="/home">{{'Clientname' | translate}}</a></p>
              <!-- <img  routerLink="/home" class="cursor-pointer m-t-10" [ngStyle.lt-md]="{'width.px': 125}"  src="https://www.directscale.com/wp-content/themes/directscale/img/logo.svg" alt="company logo" srcset=""> -->
            </div>
            <div fxLayout="row" class="m-l-30" fxLayoutAlign="center center">
              <p class="heading">
                <span class="m-l-10 f-w-100" [ngClass]="{'text-gray': '/home' ? router.isActive('/home', true): false}"
                  routerLink="/home">Home</span>
                <!-- <span class="m-l-15 f-w-100"
                  [ngClass]="{'text-gray': '/products/all' ? router.isActive('/products/all', true): false}"
                  (click)="run()" [matMenuTriggerFor]="menu">Shop <mat-icon class="display-inline-relative">
                    arrow_drop_down</mat-icon></span> -->
  
                  <span class="m-l-15 f-w-100"
                    [ngClass]="{'text-gray': '/join' ? router.isActive('/products/all', true): false}"
                    routerLink="/products/all">Shop</span>
  
  
  
                <!-- <span class="m-l-15 f-w-100" [ngClass]="{'text-gray': '/faq' ? router.isActive('/faq', true): false}"
                  routerLink="/faq">FAQ</span> -->
                <span *ngIf="!isLoggedIn()" class="m-l-15 f-w-100"
                  [ngClass]="{'text-gray': '/join' ? router.isActive('/join', true): false}"
                  [routerLink]="'/join'" [queryParams]="{ type: '1' }">Join</span>
                <!-- <span class="m-l-15 f-w-100"
                  [ngClass]="{'text-gray': '/contact' ? router.isActive('/contact', true): false}"
                  routerLink="/contact">Contact</span>
                <span class="m-l-15 f-w-100" [ngClass]="{'text-gray': '/about' ? router.isActive('/about', true): false}"
                  routerLink="/about">About</span> -->
              </p>
            </div>
            <mat-menu #menu="matMenu">
              <div id="header-menu" fxLayout="row" fxFlex="100" fxLayoutGap="20px" class="p-20 line_space ">
                <div fxLayout="row" fxFlex="100">
                  <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="flex-start space-evenly">
                    <div *ngFor="let category of allItems; let i=index" fxLayout="column" fxFlex="25" fxFlex.lt-md="48"
                      fxLayoutAlign="flex-start flex-start" fxFill>
                      <div fxFlex="24" fxFlex.lt-md="100" fxLayoutAlign="flex-start flex-start" fxLayout="column" fxFill
                        class="p-relative">
                        <b><span [routerLink]="['/products', category.CategoryId]">{{category.Category}}</span></b>
                        <span [routerLink]="['/products',category.CategoryId]">All</span>
                        <div *ngFor="let item of HomeProductlist">
                          <span *ngIf="item.CategoryId == category.CategoryId"
                            [routerLink]="['/product', item.ItemID]">{{item.ProductName}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </mat-menu>
          </div>
          <div class="mt p-l-20 p-r-20 " fxLayout="row" fxFlex="100" fxLayoutAlign="end end">
            <div>
              <app-shopping-widgets [shoppingCartItems]="shoppingCartItems" [isWhite]="isTop"></app-shopping-widgets>
            </div>
  
            <div class="enroll_img" *ngIf="!isLoggedIn()" fxLayout="row" fxLayoutAlign="center center">
              <div>
                <a class="avtar" mat-button [matMenuTriggerFor]="langMenu" #langMenuTrigger="matMenuTrigger">
                  <img width="18" [default]="'assets/images/user.png'">
                  &nbsp;<span [ngStyle.lt-sm]="{'font-size.px': 15}" class="text-uppercase f-500 heading m-l-12 f-w-100"
                    [ngClass]="{'c-white': isTop , 'c-black' : !isTop }" mat-button data-bind="label">SIGN
                    IN
                    <mat-icon class="display-inline-relative">arrow_drop_down</mat-icon>
                  </span>
                </a>
              </div>
              <mat-menu #langMenu="matMenu" [overlapTrigger]="false" xPosition="before" class="app-dropdown swal">
                <div class="logout-menu">
                  <!-- Name -->
                  <div class="enroll_img" fxLayout="row" fxLayoutAlign="space-around center">
                    <div>
                      <span [ngStyle.lt-sm]="{'font-size.px': 12}" class="text-uppercase f-500"
                        data-bind="label">{{ accountService.isLoggedIn() ? userService?.customerData?.FirstName : 'Guest' }}</span>
                    </div>
                    <div class="avtar nocursor">
                      <mat-icon>perm_identity</mat-icon>
  
                    </div>
                  </div>
                  <!-- Sign Out -->
                  <div fxLayoutAlign="space-around center" class="" *ngIf="isLoggedIn()">
                    <button class="shop-btn product-button w-60  " mat-raised-button color="black" (click)="logout()">Sign
                      Out</button>
                  </div>
                  <!-- Sign In -->
                  <div fxLayoutAlign="space-around center" class="" *ngIf="!isLoggedIn()">
                    <button class="shop-btn product-button w-60  " mat-raised-button color="black" (click)="login()">Sign
                      In</button>
                  </div>
  
                  <!-- Orders -->
                </div>
              </mat-menu>
            </div>
            <div class="enroll_img" fxLayout="row" fxLayoutAlign="center center" *ngIf="isLoggedIn()">
              <div>
                <a class="avtar" mat-button [matMenuTriggerFor]="langMenu" #langMenuTrigger="matMenuTrigger">
                  <img [src]="userServiceModal?.enrollerInfo?.ImageUrl" width="18" [default]="'assets/images/user.png'">
                  &nbsp;<span [ngStyle.lt-sm]="{'font-size.px': 15}" class="text-uppercase f-500 heading m-l-12 f-w-100"
                    [ngClass]="{'c-white': isTop , 'c-black' : !isTop }" mat-button
                    data-bind="label">{{userServiceModal?.enrollerInfo?.FirstName}}
                    &nbsp;{{userServiceModal?.enrollerInfo?.LastName}}
                    <mat-icon class="display-inline-relative">arrow_drop_down</mat-icon>
                  </span>
                </a>
              </div>
              <mat-menu #langMenu="matMenu" [overlapTrigger]="false" xPosition="before" class="app-dropdown swal">
  
                <div class="logout-menu">
                  <!-- Name -->
                  <div class="enroll_img" fxLayout="row" fxLayoutAlign="space-around center">
                    <div>
  
                      <span [ngStyle.lt-sm]="{'font-size.px': 12}" class="text-uppercase f-500" data-bind="label"
                        [textContent]="(userServiceModal?.customerData?.FirstName || userServiceModal?.enrollerInfo?.FirstName) + ' ' + (userServiceModal?.customerData?.LastName || userServiceModal?.enrollerInfo?.LastName)"></span>
  
                    </div>
                    <div class="avtar nocursor">
                      <img style="height:45px;width:45px;"
                        [src]="userServiceModal?.customerData?.ImageUrl || userServiceModal?.enrollerInfo?.ImageUrl"
                        alt="companylogo" [default]="'assets/images/user.png'">
                    </div>
                  </div>
                  <!-- Sign Out -->
                  <div fxLayoutAlign="space-around center" class="" *ngIf="isLoggedIn()">
                    <button class="shop-btn product-button w-60  " mat-raised-button color="black" (click)="logout()">Sign
                      Out</button>
                  </div>
                  <!-- Sign In -->
                  <div fxLayoutAlign="space-around center" class="" *ngIf="!isLoggedIn()">
                    <button class="shop-btn product-button w-60" (click)="login()" mat-raised-button color="black">Sign
                      In</button>
                  </div>
                  <!-- Orders -->
                  <a>
                    <div fxLayoutAlign=" stretch" class=" receiptOrder" fxLayout="row" routerLink="/orderhistory">
                      <mat-icon class="receipt">receipt</mat-icon>
                      <span *ngIf="isLoggedIn()" class="m-l-7 f-w-100"
                        [ngClass]="{'text-gray': '/orderhistory' ? router.isActive('/orderhistory', true): false}">Order
                        History</span>
                    </div>
                  </a>
  
                  <!--MAnage Autoship-->
                  <a>
                    <div fxLayoutAlign=" stretch" class=" " fxLayout="row" routerLink="/manageautoship">
                      <mat-icon class="receipt">assessment</mat-icon>
                      <span *ngIf="isLoggedIn()" class="m-l-17 f-w-100"
                        [ngClass]="{'text-gray': '/   manageautoship' ? router.isActive('/manageautoship', true): false}">Manage
                        Autoship</span>
                    </div>
                  </a>
  
                </div>
              </mat-menu>
            </div>
          </div>
        </mat-toolbar-row>
      </mat-toolbar>
      <mat-toolbar fxHide fxShow.lt-md color="primary"
        [ngClass]="{'noColor header p-0-10': isTop , 'kuro header' : !isTop }" fxFlex="100">
        <mat-toolbar-row class="container p-0 h-90">
          <div fxLayout="row" fxFlex="100">
            <div class="mt" fxLayout="row" fxFlex="33" fxLayoutAlign="flex-start flex-start">
              <div class="logo1">
                <span fxHide="false" fxHide.gt-sm>
                  <button mat-icon-button class="mat" (click)="toggleMobileMenu($event)">
                    <mat-icon>menu</mat-icon>
                  </button>
                </span>
              </div>
            </div>
            <div fxLayout="row" fxFlex="33" class="m-t-0" fxLayoutAlign="center center">
              <p [ngClass]="{'p-width': isTop , 'p-width2' : !isTop }"><a class="c-white logo"
                  [ngClass]="{'c-white': isTop , 'c-black' : !isTop }" routerLink="/home">{{'Clientname' | translate}}</a></p>
              <!-- <img  routerLink="/home" [ngStyle.lt-sm]="{'width.px': 121}"   class="cursor-pointer m-t-10" src="https://www.directscale.com/wp-content/themes/directscale/img/logo.svg" alt="company logo" srcset=""> -->
            </div>
            <div class="mt p-l-20 p-r-20 " fxLayout="row" fxFlex="33" fxLayoutAlign="end end">
              <div>
                <app-shopping-widgets [shoppingCartItems]="shoppingCartItems" [isWhite]="isTop"></app-shopping-widgets>
              </div>
            </div>
          </div>
        </mat-toolbar-row>
      </mat-toolbar>
    </ng-container>
  </ng-template>

  <!-- *ngIf="currentRouterPath != '/home'" -->
  <ng-container >

    <!-- Desktop -->
    <mat-toolbar fxShow fxHide.lt-md color="primary" class="kuro header header-long" fxFlex="100" fxLayoutAlign="center center">
      <mat-toolbar-row class="container p-0 h-90 p-r-10" style="max-width: 1279px;">
        
        <!-- NEW HEADER #5693 -->
        <div fxLayout="row" class="new-5star-header w-100">
          <div fxFlex="50" fxLayoutAlign="start center">
            <img routerLink="/" src="../../../../assets/images/5starlogo.png" alt="" class="img-fluid cursor-pointer"
            width="100px" height="72px">
          </div>
          <div fxFlex="50" fxLayoutAlign="end center" fxLayoutGap="25px">
            <div fxLayoutAlign="end center" fxLayoutGap="35px" class="nav-links">
              <span routerLink="/products/all">SHOP</span>
              <span (click)="scrollToEventsSection()">EVENTS</span>
              <span *ngIf="!isLoggedIn()" routerLink="/become-a-distributor">JOIN US</span>
            </div>

            <div class="color-white fade-80" fxLayoutAlign="end center" fxLayoutGap="7px" >
              <mat-icon class="cursor-pointer" [matMenuTriggerFor]="langMenu" #langMenuTrigger="matMenuTrigger">person</mat-icon>
              <app-shopping-widgets [shoppingCartItems]="shoppingCartItems" [isWhite]="false"></app-shopping-widgets>
              <img [matMenuTriggerFor]="headerLanguageSelector" class="flag-icon" [src]="'https://directscalestring.directscale.com/flags/4x3/'+ configService.commonData.selectedCountry?.toLowerCase() +'.svg'" width="26px"/>
            </div>

            <!-- Person Menu -->
            <mat-menu #langMenu="matMenu" [overlapTrigger]="false" xPosition="before" class="app-dropdown swal">
              <div class="logout-menu">
                <!-- Name -->
                <div class="enroll_img" fxLayout="row" fxLayoutAlign="space-around center">
                  <div>
                    <span [ngStyle.lt-sm]="{'font-size.px': 12}" class="text-uppercase f-500"
                      data-bind="label">{{ accountService.isLoggedIn() ? userService?.customerData?.FirstName : 'Guest' }}</span>
                  </div>
                  <div class="avtar nocursor">
                    <mat-icon>perm_identity</mat-icon>

                  </div>
                </div>
                <!-- Sign Out -->
                <div fxLayoutAlign="space-around center" class="" *ngIf="isLoggedIn()">
                  <button class="shop-btn product-button w-60  " mat-raised-button color="black"
                    (click)="logout()">Sign Out</button>
                </div>
                <!-- Sign In -->
                <div fxLayoutAlign="space-around center" class="" *ngIf="!isLoggedIn()">
                  <button class="shop-btn product-button w-60  " mat-raised-button color="black"
                    (click)="login()">Sign In</button>
                </div>
                <!-- Orders -->
                <a *ngIf="isLoggedIn()">
                  <div fxLayoutAlign=" stretch" class="avtar receiptOrder" fxLayout="row" routerLink="/orderhistory">
                    <mat-icon class="receipt">receipt</mat-icon>
                    <span *ngIf="isLoggedIn()" class="m-l-7 f-w-100"
                      [ngClass]="{'text-gray': '/orderhistory' ? router.isActive('/orderhistory', true): false}">Order
                      History</span>
                  </div>
                </a>
                <!--Manage Autoship-->
                <a *ngIf="isLoggedIn()">
                  <div fxLayoutAlign=" stretch" class="avtar " fxLayout="row" routerLink="/manageautoship">
                    <mat-icon class="receipt">assessment</mat-icon>
                    <span *ngIf="isLoggedIn()" class="m-l-7 f-w-100"
                      [ngClass]="{'text-gray': '/   manageautoship' ? router.isActive('/manageautoship', true): false}">Manage
                      Autoship</span>
                  </div>
                </a>
              </div>
            </mat-menu>

            <!-- Language Selector Menu -->
            <mat-menu #headerLanguageSelector="matMenu" [overlapTrigger]="false" xPosition="before" class="app-dropdown">
              <span *ngFor="let country of allowedCountries">
                <button mat-menu-item (click)="updatecountry(country, lng.LanguageCode)"
                  *ngFor="let lng of country.CountryLanguages">
                  <img [src]="'https://directscalestring.directscale.com/flags/4x3/'+country.CountryCode+'.svg'"
                    width="18"> {{country.CountryName}} | {{lng.LanguageName}}
                </button>
              </span>
            </mat-menu>
            
          </div>
        </div>

        <!-- OLD HEADER  -->
        <ng-template>
          <div class="mt p-l-20 p-r-20" fxLayout="row">
            <div>
              <p><a class="c-black logo" routerLink="/home">{{'Clientname' | translate}}</a></p>
              <!-- <img  routerLink="/home" class="cursor-pointer m-t-10" [ngStyle.lt-md]="{'width.px': 125}"  src="https://www.directscale.com/wp-content/themes/directscale/img/logo.svg" alt="company logo" srcset=""> -->
            </div>
            <div fxLayout="row" class="m-l-30" fxLayoutAlign="center center">
              <p class="heading">
                <span class="m-l-10 f-w-100" [ngClass]="{'text-gray': '/home' ? router.isActive('/home', true): false}"
                  routerLink="/home">Home</span>
                <!-- <span class="m-l-15 f-w-100"
                  [ngClass]="{'text-gray': '/products/all' ? router.isActive('/products/all', true): false}"
                  (click)="run()" [matMenuTriggerFor]="menu">Shop <mat-icon class="display-inline-relative">
                    arrow_drop_down</mat-icon></span> -->
                <span class="m-l-15 f-w-100"
                  [ngClass]="{'text-gray': '/join' ? router.isActive('/products/all', true): false}"
                  routerLink="/products/all">Shop</span>
                <!-- <span class="m-l-15 f-w-100" [ngClass]="{'text-gray': '/faq' ? router.isActive('/faq', true): false}"
                  routerLink="/faq">FAQ</span> -->
                <span *ngIf="!isLoggedIn()" class="m-l-15 f-w-100"
                  [ngClass]="{'text-gray': '/join' ? router.isActive('/join', true): false}"
                  [routerLink]="'/join'" [queryParams]="{ type: '1' }">Join</span>
                <!-- <span class="m-l-15 f-w-100"
                  [ngClass]="{'text-gray': '/contact' ? router.isActive('/contact', true): false}"
                  routerLink="/contact">Contact</span>
                <span class="m-l-15 f-w-100" [ngClass]="{'text-gray': '/about' ? router.isActive('/about', true): false}"
                  routerLink="/about">About</span> -->
               </p>
            </div>
          </div>
          <mat-menu #menu="matMenu">
            <div id="header-menu" fxLayout="row" fxFlex="100" fxLayoutGap="20px" class="p-20 line_space">
              <div fxLayout="row" fxFlex="100">
                <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="flex-start space-evenly">
                  <div *ngFor="let category of allItems; let i=index" fxLayout="column" fxFlex="25" fxFlex.lt-md="48"
                    fxLayoutAlign="flex-start flex-start" fxFill>
                    <div fxFlex="24" fxFlex.lt-md="100" fxLayoutAlign="flex-start flex-start" fxLayout="column" fxFill
                      class="p-relative">
                      <b><span [routerLink]="['/products', category.CategoryId]">{{category.Category}}</span></b>
                      <span [routerLink]="['/products',category.CategoryId]">All</span>
                      <div *ngFor="let item of HomeProductlist">
                        <span *ngIf="item.CategoryId == category.CategoryId"
                          [routerLink]="['/product', item.ItemID]">{{item.ProductName}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-menu>
          <div class="mt p-l-20 p-r-20 " fxLayout="row" fxFlex="100" fxLayoutAlign="end end">
            <div>
              <app-shopping-widgets [shoppingCartItems]="shoppingCartItems" [isWhite]="false"></app-shopping-widgets>
            </div>
            <div>
              <!-- <span  class="m-l-15 f-w-100"> -->
              <div class="enroll_img" *ngIf="!isLoggedIn()" fxLayout="row" fxLayoutAlign="center center">
                <div>
                  <a class="avtar" mat-button [matMenuTriggerFor]="langMenu" #langMenuTrigger="matMenuTrigger">
                    <img width="18" [default]="'assets/images/user.png'">
                    &nbsp;<span [ngStyle.lt-sm]="{'font-size.px': 15}" class="text-uppercase f-500 heading m-l-12 f-w-100"
                      mat-button data-bind="label">SIGN
                      IN
                      <mat-icon class="display-inline-relative">arrow_drop_down</mat-icon>
                    </span>
                  </a>
                </div>
                <mat-menu #langMenu="matMenu" [overlapTrigger]="false" xPosition="before" class="app-dropdown swal">
                  <div class="logout-menu">
                    <!-- Name -->
                    <div class="enroll_img" fxLayout="row" fxLayoutAlign="space-around center">
                      <div>
                        <span [ngStyle.lt-sm]="{'font-size.px': 12}" class="text-uppercase f-500"
                          data-bind="label">{{ accountService.isLoggedIn() ? userService?.customerData?.FirstName : 'Guest' }}</span>
                      </div>
                      <div class="avtar nocursor">
                        <mat-icon>perm_identity</mat-icon>
  
                      </div>
                    </div>
                    <!-- Sign Out -->
                    <div fxLayoutAlign="space-around center" class="" *ngIf="isLoggedIn()">
                      <button class="shop-btn product-button w-60  " mat-raised-button color="black"
                        (click)="logout()">Sign Out</button>
                    </div>
                    <!-- Sign In -->
                    <div fxLayoutAlign="space-around center" class="" *ngIf="!isLoggedIn()">
                      <button class="shop-btn product-button w-60  " mat-raised-button color="black"
                        (click)="login()">Sign In</button>
                    </div>
                    <!-- Orders -->
                  </div>
                </mat-menu>
              </div>
              <!-- </span> -->
            </div>
  
            <div class="enroll_img" fxLayout="row" fxLayoutAlign="center center">
              <div *ngIf="isLoggedIn()">
                <a class="avtar" mat-button [matMenuTriggerFor]="langMenu" #langMenuTrigger="matMenuTrigger">
                  <img [src]="userServiceModal?.enrollerInfo?.ImageUrl" width="18" [default]="'assets/images/user.png'">
                  &nbsp;<span [ngStyle.lt-sm]="{'font-size.px': 15}" class="text-uppercase f-500 heading m-l-12 f-w-100"
                    mat-button data-bind="label">{{userServiceModal?.enrollerInfo?.FirstName}}
                    &nbsp;{{userServiceModal?.enrollerInfo?.LastName}}
                    <mat-icon class="display-inline-relative">arrow_drop_down</mat-icon>
                  </span>
                </a>
              </div>
              <mat-menu #langMenu="matMenu" [overlapTrigger]="false" xPosition="before" class="app-dropdown swal">
                <div class="logout-menu">
                  <!-- Name -->
                  <div class="enroll_img" fxLayout="row" fxLayoutAlign="space-around center">
                    <div>
                      <span [ngStyle.lt-sm]="{'font-size.px': 12}" class="text-uppercase f-500" data-bind="label"
                        [textContent]="(userServiceModal?.customerData?.FirstName || userServiceModal?.enrollerInfo?.FirstName) + ' ' + (userServiceModal?.customerData?.LastName || userServiceModal?.enrollerInfo?.LastName)"></span>
                    </div>
                    <div class="avtar nocursor">
                      <img style="height:45px;width:45px;"
                        [src]="userServiceModal?.customerData?.ImageUrl || userServiceModal?.enrollerInfo?.ImageUrl"
                        alt="companylogo" [default]="'assets/images/user.png'">
  
                    </div>
                  </div>
                  <!-- Sign Out -->
                  <div fxLayoutAlign="space-around center" class="" *ngIf="isLoggedIn()">
                    <button class="shop-btn product-button w-60  " mat-raised-button color="black" (click)="logout()">Sign
                      Out</button>
                  </div>
                  <!-- Sign In -->
                  <div fxLayoutAlign="space-around center" class="" *ngIf="!isLoggedIn()">
                    <button class="shop-btn product-button w-60  " mat-raised-button color="black" (click)="login()">Sign
                      In</button>
                  </div>
                  <!-- Orders -->
                  <a>
                    <div fxLayoutAlign=" stretch" class="avtar receiptOrder" fxLayout="row" routerLink="/orderhistory">
                      <mat-icon class="receipt">receipt</mat-icon>
                      <span *ngIf="isLoggedIn()" class="m-l-7 f-w-100"
                        [ngClass]="{'text-gray': '/orderhistory' ? router.isActive('/orderhistory', true): false}">Order
                        History</span>
                    </div>
                  </a>
                  <!--Manage Autoship-->
                  <a>
                    <div fxLayoutAlign=" stretch" class="avtar " fxLayout="row" routerLink="/manageautoship">
                      <mat-icon class="receipt">assessment</mat-icon>
                      <span *ngIf="isLoggedIn()" class="m-l-7 f-w-100"
                        [ngClass]="{'text-gray': '/   manageautoship' ? router.isActive('/manageautoship', true): false}">Manage
                        Autoship</span>
                    </div>
                  </a>
                </div>
              </mat-menu>
            </div>
          </div>
        </ng-template>
        
      </mat-toolbar-row>
    </mat-toolbar>

    <!-- Mobile -->
    <mat-toolbar fxHide fxShow.lt-md color="primary" class="kuro header" fxFlex="100">
      <mat-toolbar-row class="container p-0 h-90">
        <div fxLayout="row" fxFlex="100">
          <div class="mt" fxLayout="row" fxFlex="33" fxLayoutAlign="flex-start flex-start">
            <div class="logo1">
              <span fxHide="false" fxHide.gt-sm>
                <button mat-icon-button class="mat color-white" (click)="toggleMobileMenu($event)">
                  <mat-icon>menu</mat-icon>
                </button>
              </span>
            </div>
          </div>
          <!-- <div fxLayout="row" fxFlex="33" fxLayoutAlign="center center">
          <p ><a class="c-black logo"  routerLink="/home">Sam</a></p>
          </div> -->
          <div fxLayout="row" fxFlex="33" class="m-t-10" fxLayoutAlign="center center">
            <!-- <p class="p-width2"><a class="c-black logo" routerLink="/home">{{'Clientname' | translate}}</a></p> -->
            <div fxLayoutAlign="start center">
              <img routerLink="/" src="../../../../assets/images/5starlogo.png" alt="" class="img-fluid cursor-pointer"
              width="40px">
            </div>
          </div>
          <div class="mt p-l-20 p-r-20 " fxLayout="row" fxFlex="33" fxLayoutAlign="end end">
            <div>
              <app-shopping-widgets [shoppingCartItems]="shoppingCartItems" [isWhite]="false"></app-shopping-widgets>
            </div>
           
          </div>
        </div>
      </mat-toolbar-row>
    </mat-toolbar>
  </ng-container>
</div>