import { HttpClient } from "@angular/common/http";
import { Component, ElementRef, HostListener, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";
import { LoaderService } from "../../services/loader.service";
import { Router } from "@angular/router";
import { NotificationService } from "../../services/notification.service";
export function OnIFrameSave(data) {
}
export function OnIFrameError(error) {
  if (!error) {
    return;
  }
  // window.alert(error);
}
@Component({
  selector: "app-hostedpayment-dialog",
  templateUrl: "./hostedpayment-dialog.component.html",
  styleUrls: ["./hostedpayment-dialog.component.scss"],
})
export class HostedpaymentDialogComponent implements OnInit {
  public paymentData: any = {};
  public iFrameUrl;
  showIsLoading: boolean = true;
  showNoProductsError: boolean = false;

  constructor(
    public sanitizer: DomSanitizer,
    public http: HttpClient,
    private loader: LoaderService,
    public router: Router,
    private elementRef: ElementRef,
    public dialogRef: MatDialogRef<HostedpaymentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public notificationService: NotificationService,
  ) {
    // this.dialogRef.disableClose = true;
    if(this.data.isCheckout){
      if(this.data?.Script){
        this.paymentData = this.data;
      }
      else{
        this.paymentData = this.data?.IFrameUrl;
      }
    }
    else{
      this.paymentData = this.data?.IFrameUrl;
    }
  }




  // @HostListener("window:message", ["$event"])
  // onMessage(e) {
  //   if (e.data.event == "cardSaved") {
  //     // e.data nexio card event
  //     this.dialogRef.close(e.data.data);
  //   } else if (e.data.isTestCardEvent == true) {
  //     // e.data test card event
  //     this.dialogRef.close(e.data);
  //   }
  // }
  @HostListener('window:message', ['$event'])
  onMessage(e) {
    console.log('iframe data', e.data);
    if (e.data.event == 'cardSaved') {
      // e.data nexio card event
      this.dialogRef.close(e.data.data);
    } else if (e.data.isTestCardEvent == true) {
      // e.data test card event
      this.dialogRef.close(e.data);
    } else {
      // e.data. has no event in NMI case so handling this case
      if (this.paymentData.Script) {
        this.dialogRef.close(e.data);
      }

    }
    if (e) {
      this.handleIframeMessage(e);
    }
  }
  @HostListener("window:OnIFrameSave", ["$event"])
  OnIFrameSave(data) {
    console.log(data);
  }
  @HostListener("window:OnIFrameError", ["$event"])
  OnIFrameError(data) {
    console.log(data);

  }
  ngOnInit(): void {
    this.setUpIframeMethods();
    if (this.paymentData && this.paymentData.Script) {
      this.executePaymentScripts(this.paymentData.Script);
    }
    else {
      //     const iframe = document.getElementById('iframDSPayment') as HTMLIFrameElement;
      //     const checkIframe = setInterval(() => {
      //       try {
      //         if (iframe.contentDocument && iframe.contentDocument.readyState === 'complete') {
      //           console.log('Iframe is completely loaded and accessible!');
      //           iframe.onload = () => {
      //             iframe.contentWindow.postMessage({ action: 'styleButton', styles: { cursor:'pointer' } }, '*');
      //           };
      //           clearInterval(checkIframe);
      //         }
      //       } catch (error) {
      //         console.log('Waiting for iframe to load...');
      //       }
      //     }, 100);
    }
    if (this.paymentData?.IFrameUrl) {
      this.iFrameUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        this.paymentData.IFrameUrl,
      );
    } else {
      this.iFrameUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        this.paymentData,
      );
    }
  }
  handleIframeMessage(event: MessageEvent) {

    if (event.data.action === 'styleButton') {
      const button = document.getElementById('submitButton');
      const styles = event.data.styles;
  
      Object.keys(styles).forEach((style) => {
        button.style[style] = styles[style];
      });

      button.addEventListener('click', () => {
        if (!event.data?.data?.isFormValid) {
          this.notificationService.error(
            "error",
            'Please fill in all required fields correctly.'
          );
          return;
        }
      });
    }

    const loader = this.elementRef.nativeElement.querySelector('#loader');
    const eventData = event.data?.data;
    const eventType = event.data?.event;
    if (eventType === 'submit') {
      loader.style.display = 'flex';
    }
    if (eventType === 'processed') {
      loader.style.display = 'none';
      setTimeout(() => window['handleIframeEvent'], 3000);
      sessionStorage.setItem("customerLoginDetails", JSON.stringify(eventData))
      localStorage.setItem("customerLoginDetails", JSON.stringify(eventData))
      this.dialogRef.close(true);
    }
  }

  cleanupCustomJS(): void {
    delete window['handleIframeEvent'];
  }

  onConfirm(data): void {
    console.log(data);
    // Close the dialog, return true
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }
  getIframeHeight(): string {
    return this.paymentData.Height;
  }

  getIframeUrl(): any {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.paymentData.IFrameUrl);
  }

  getIframeWidth(): string {
    return this.paymentData.Width;
  }

  executePaymentScripts(html) {
    // Extract, load and execute <script src> tags
    // Extract and execute <script> tags with inline javascript

    const externalScripts = this.getExternalScripts(html);
    html = this.removeExternalScriptTags(html);

    this.runExternalScripts(externalScripts).then(() => {
      const inlineScripts = this.getInlineScripts(html);
      this.runInlineScripts(inlineScripts);
    });
  }

  getExternalScripts(html) {
    const externalScriptOpenRegex = /<script[^>]*src="[^"]*"[^>]*>/g;
    const srcAttrRegex = /src="([^"]*)"/;

    const scriptTags = html.match(externalScriptOpenRegex);

    if (!scriptTags) {
      return [];
    }

    return scriptTags
      .map((tag) => {
        const link = srcAttrRegex.exec(tag);

        if (!link) {
          return null;
        }

        // Index 1 is the first capture group result
        return link[1];
      })
      .filter((link) => {
        return !!link;
      });
  }

  getInlineScripts(html) {
    const inlineScriptTagRegex = /<script[^>]*>([\s\S]*)<\/script>/g;
    const scriptTags = html.match(inlineScriptTagRegex);

    if (!scriptTags) {
      return [];
    }

    return scriptTags
      .map((tag) => {
        const script = inlineScriptTagRegex.exec(tag);

        if (!script) {
          return null;
        }

        // Index 1 is the first capture group result
        return script[1];
      })
      .filter((script) => {
        return !!script;
      });
  }

  removeExternalScriptTags(html) {
    const externalScriptRegex = /<script[^>]*src="[^"]*"[^>]*>.*<\/script>/g;
    return html.replace(externalScriptRegex, "");
  }

  runExternalScripts(urlArray) {
    const promise = new Promise((resolve) => {
      const firstScript = urlArray.splice(0, 1)[0];
      if (!firstScript) {
        resolve(true);
        return promise;
      }

      this.http.get<any>(firstScript).subscribe(() => {
        this.runExternalScripts(urlArray);
        resolve(true);
      });
    });
    return promise;
  }
  runInlineScripts(scriptArray) {
    window.eval(scriptArray.join("\n;\n"));
  }
  setUpIframeMethods() {
    window["OnIFrameSave"] = OnIFrameSave;
    window["OnIFrameError"] = OnIFrameError;
  }
}
